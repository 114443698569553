import { graphql, useStaticQuery } from 'gatsby';
import AWSIcon from 'svgs/single-case-study/aws.svg';
import NodeIcon from 'svgs/single-case-study/node-js.svg';
import ReactIcon from 'svgs/single-case-study/react-js.svg';
import UxUiIcon from 'svgs/single-case-study/ux-ui-design.svg';
import WebDevelopmentIcon from 'svgs/single-case-study/web-development.svg';

import { contentResolver } from '../utilities';

const faqPath = 'faq-section.content';
const FAQ_ITEMS = [
    {
        question: `${faqPath}.q1`,
        answer: `${faqPath}.a1`,
    },
    {
        question: `${faqPath}.q2`,
        answer: `${faqPath}.a2`,
    },
    {
        question: `${faqPath}.q3`,
        answer: `${faqPath}.a3`,
    },
    {
        question: `${faqPath}.q4`,
        answer: `${faqPath}.a4`,
    },
    {
        question: `${faqPath}.q5`,
        answer: `${faqPath}.a5`,
    },
    {
        question: `${faqPath}.q6`,
        answer: `${faqPath}.a6`,
    },
];

const content = contentResolver({
    project: 'objekto',
    brandColor: '--objekto-case-study',
    brandColorGradient: {
        start: '--objekto-case-study',
        stop: '--objekto-case-study',
    },
    scopeOfWork: [
        {
            title: 'scopeOfWork.webDevelopment',
            Icon: WebDevelopmentIcon,
        },
        {
            title: 'scopeOfWork.reactJs',
            Icon: ReactIcon,
        },
        {
            title: 'scopeOfWork.uxUiDesign',
            Icon: UxUiIcon,
        },
        {
            title: 'scopeOfWork.nodeJs',
            Icon: NodeIcon,
        },
        {
            title: 'scopeOfWork.awsCloud',
            Icon: AWSIcon,
        },
    ],
    projectInfo: {
        count: 2,
        dataIndexes: [0, 2],
        colors: ['--objekto-case-study', '--objekto-case-study'],
    },
    paragraphsCounters: {
        header: 1,
        about: 2,
        challenge: 4,
        mainGoals: 4,
        solutions: 4,
        summary: 5,
    },
    caseStudies: ['desaLocum', 'turo', 'cbreZone'],
    caseStudiesSubtitle: 'case-studies.subtitle',
    faqTitle: 'faq-section.title',
    faqData: FAQ_ITEMS,
});

content.headerImageAlt = 'facility management software';
content.about.alt = 'software facilities management';
content.challenge.alt = 'facility management software development';
content.mainGoals.alt = 'software facilities management development';
content.solutions.alt = 'facility management software development app';
content.summary.alt = 'software facilities management app';

export const useObjekto = () => {
    const images = useStaticQuery(
        graphql`
            query {
                headerImage: file(
                    relativePath: { eq: "single-case-study/objekto/laptop.png" }
                ) {
                    ...ImageCaseStudyConfig
                }
                aboutImage: file(
                    relativePath: { eq: "single-case-study/objekto/about.png" }
                ) {
                    ...ImageCaseStudyConfig
                }
                challengeImage: file(
                    relativePath: {
                        eq: "single-case-study/objekto/challenge.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                mainGoalsImage: file(
                    relativePath: {
                        eq: "single-case-study/objekto/main-goals.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                solutionsImage: file(
                    relativePath: {
                        eq: "single-case-study/objekto/solutions.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                summaryImage: file(
                    relativePath: {
                        eq: "single-case-study/objekto/summary.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
            }
        `,
    );

    return {
        data: { ...images, ...content },
    };
};
